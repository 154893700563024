import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Typography from "@material-ui/core/Typography"
import Container from "@material-ui/core/Container"
import styled from "styled-components"
import SadFaceSVG from "../images/sad-face-outline.svg"
import { Link } from "gatsby"
import Button from "@material-ui/core/Button"

const StyledHeader = styled.h1`
  font-size: 24px;
  text-transform: uppercase;
`
const StyledContainerWrpaper = styled.div`
  padding: 40px 0;
  text-align: center;
`
const StyledSadFaceSVG = styled.img`
  display: inline-block;
  max-width: 150px;
  margin: 40px 0;
`
const StyledButton = styled(Button)`
  display: block;
  width: 100%;
`
const HomeLink = React.forwardRef((props, ref) => (
  <Link innerRef={ref} to="/" {...props} />
))
const PaymentFailed = () => {
  return (
    <Layout>
      <SEO title="Home"/>
      <Container maxWidth={"lg"}>
        <StyledContainerWrpaper>
          <StyledHeader>Wystąpił błąd</StyledHeader>
          <StyledSadFaceSVG src={SadFaceSVG}/>
          <Typography>Sprawdź swoje środki na koncie i spróbuj jeszcze raz.</Typography><br/>
          <Typography>Jeśli problem będzie dalej występował to skorzystaj z konwencjonalnych sposobów
            płatności.</Typography><br/>
          <Typography>Przepraszamy za utrudnienia!</Typography><br/><br/>
          <StyledButton variant="contained" color="primary" size="large"
                        component={HomeLink}>Wróć do strony głównej</StyledButton>
        </StyledContainerWrpaper>
      </Container>
    </Layout>
  )
}

export default PaymentFailed
